import { useDispatch ,useSelector} from "react-redux";
import axios from 'axios';
import { setWorkDetails } from '../reducer/userSlice';




function Workdetails(){
    const dispatch=useDispatch();
    const workdetailsData=useSelector((state)=>state.user.WorkDetails)
    // const clientloginid=useSelector((state)=>state.user.clientLogin)
    let clientloginid=localStorage.getItem("clientid")
    
    let workdetailsget=()=>{
        let formData= new FormData()
        formData.append("client_id",workdetailsData.clientid)
        formData.append("work",workdetailsData.work)
        formData.append("work_place",workdetailsData.workplace)
        formData.append("request","work_details")

        axios.post('https://barish.pythonanywhere.com/work_details',formData)
        .then(response=>{
           console.log(response)})
        // localStorage.removeItem("clientid")})
    }
    return(
        <>
        <h1>Work</h1>
        <form class="form" style={{marginLeft:"42%"}}>
    <p class="title">G!VE DETAILS </p>      
    <label>
        <input class="input" defaultValue={clientloginid} onChange={(e)=>dispatch(setWorkDetails({...workdetailsData,clientid:e.target.value}))} type="text" placeholder="" required=""/>
        <span>clientId</span>
    </label>
    <label>

    <span>work :</span>   
    <select value={workdetailsData.work} onChange={(e) => dispatch(setWorkDetails({...workdetailsData,work:e.target.value}))}>
   
            <option value="" selected disabled hidden>select work</option>
            <option value="Webdevelopment ">Webdevelopment</option>

            <option value="Driver">Driver</option>

            <option value="Plumber">Plumber</option>

            <option value="Electrical work">Electrical work</option>

            <option value="Interiordesign">Interiordesign</option>

            </select>
           
            </label>
   
    <label>
        <input class="input" onKeyUp={(e)=>dispatch(setWorkDetails({...workdetailsData,workplace:e.target.value}))} type="text" placeholder="" required=""/>
        <span>workplace</span>
    </label>
    
    <button class="submit" type='button' onClick={()=>workdetailsget()}>Submit</button>
</form>
        </>
    )
}

export default Workdetails;